/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  -ms-overflow-style: none;
}*/

::-webkit-scrollbar {
  /*display: none;*/
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.logo {
  width: 95.63px;
  height: 30px;
}
.text-medium-10px {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: "#666666" !important;
  margin-left: -24px;
}

.text-medium-12px {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  color: "#666666";
}
.text-medium-14px {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: "#666666";
}
.buttonFullDetails {
  position: static;
  left: 7.21%;
  right: 7.21%;
  top: 16.67%;
  bottom: 16.67%;

  /* Components/Button Medium */

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.46px;
  text-transform: uppercase;

  /* Primary/Main */

  color: #7a58bf;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
.padding-table {
  padding: 15px;
}
.TextField-with-border-radius fieldset {
  border-radius: 14px;
}

html {
  scroll-behavior: smooth;
}

/* .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
  white-space: unset !important;
} */

.MuiSelect-select {
  white-space: unset !important;
}

.inputForNumber {
  min-width: 100px !important;
}

.z-index-9999 {
  z-index: 9999 !important;
}

.mt-16 {
  margin-top: 4rem !important;
}
